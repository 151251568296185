var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-semibold mb-9",attrs:{"color":"primary"}},[_vm._v(" Propulsion Efficiency "),_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","medium":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.propulsionEfficiencyToolTooltipText))])]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{staticClass:"layout-action-list"},[_c('v-list-item',{on:{"click":function($event){return _vm.expandWidget()}}},[_c('v-list-item-title',[_vm._v("Expand widget")])],1)],1)],1)],1),_c('v-card-subtitle',[_c('div',{staticClass:"actions d-flex mb-4"},[_c('div',{staticClass:"date-pickers d-inline-flex"},[_c('div',{staticClass:"date-time input d-inline-flex"},[_c('label',{staticClass:"period-1"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-range")]),_vm._v(" Period #1")],1),_c('v-menu',{ref:"menuFirstPeriodFromDate",staticStyle:{"z-index":"60"},attrs:{"close-on-content-click":false,"return-value":_vm.firstPeriodFromDate,"transition":"slide-x-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.firstPeriodFromDate=$event},"update:return-value":function($event){_vm.firstPeriodFromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.firstPeriodFromDate)+" ")])]}}]),model:{value:(_vm.isFirstPeriodFromDateModalActive),callback:function ($$v) {_vm.isFirstPeriodFromDateModalActive=$$v},expression:"isFirstPeriodFromDateModalActive"}},[_c('v-date-picker',{attrs:{"min":_vm.vesselLogDataMinDate,"max":_vm.currentDate},on:{"input":function($event){return _vm.onPeriodDateChanged('menuFirstPeriodFromDate', _vm.firstPeriodFromDate)}},model:{value:(_vm.firstPeriodFromDate),callback:function ($$v) {_vm.firstPeriodFromDate=$$v},expression:"firstPeriodFromDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("Start date")])]],2)],1),_c('span',{staticClass:"mx-1"},[_vm._v("–")]),_c('v-menu',{ref:"menuFirstPeriodToDate",staticStyle:{"z-index":"60"},attrs:{"close-on-content-click":false,"return-value":_vm.firstPeriodToDate,"transition":"slide-x-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.firstPeriodToDate=$event},"update:return-value":function($event){_vm.firstPeriodToDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.firstPeriodToDate)+" ")])]}}]),model:{value:(_vm.isFirstPeriodToDateModalActive),callback:function ($$v) {_vm.isFirstPeriodToDateModalActive=$$v},expression:"isFirstPeriodToDateModalActive"}},[_c('v-date-picker',{attrs:{"color":"primary lighten-2","min":_vm.firstPeriodFromDate,"max":_vm.currentDate},on:{"input":function($event){return _vm.onPeriodDateChanged('menuFirstPeriodToDate', _vm.firstPeriodToDate)}},model:{value:(_vm.firstPeriodToDate),callback:function ($$v) {_vm.firstPeriodToDate=$$v},expression:"firstPeriodToDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("End date")])]],2)],1)],1),_c('div',{staticClass:"date-time input d-inline-flex"},[_c('label',{staticClass:"period-2"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-range")]),_vm._v(" Period #2")],1),_c('v-menu',{ref:"menuSecondPeriodFromDate",staticStyle:{"z-index":"60"},attrs:{"close-on-content-click":false,"return-value":_vm.secondPeriodFromDate,"transition":"slide-x-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.secondPeriodFromDate=$event},"update:return-value":function($event){_vm.secondPeriodFromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.secondPeriodFromDate)+" ")])]}}]),model:{value:(_vm.isSecondPeriodFromDateModalActive),callback:function ($$v) {_vm.isSecondPeriodFromDateModalActive=$$v},expression:"isSecondPeriodFromDateModalActive"}},[_c('v-date-picker',{attrs:{"min":_vm.vesselLogDataMinDate,"max":_vm.currentDate},on:{"input":function($event){return _vm.onPeriodDateChanged('menuSecondPeriodFromDate', _vm.secondPeriodFromDate)}},model:{value:(_vm.secondPeriodFromDate),callback:function ($$v) {_vm.secondPeriodFromDate=$$v},expression:"secondPeriodFromDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("Start date")])]],2)],1),_c('span',{staticClass:"mx-1"},[_vm._v("–")]),_c('v-menu',{ref:"menuSecondPeriodToDate",staticStyle:{"z-index":"60"},attrs:{"close-on-content-click":false,"return-value":_vm.secondPeriodToDate,"transition":"slide-x-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.secondPeriodToDate=$event},"update:return-value":function($event){_vm.secondPeriodToDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.secondPeriodToDate)+" ")])]}}]),model:{value:(_vm.isSecondPeriodToDateModalActive),callback:function ($$v) {_vm.isSecondPeriodToDateModalActive=$$v},expression:"isSecondPeriodToDateModalActive"}},[_c('v-date-picker',{attrs:{"min":_vm.secondPeriodFromDate,"color":"primary lighten-2","max":_vm.currentDate},on:{"input":function($event){return _vm.onPeriodDateChanged('menuSecondPeriodToDate', _vm.secondPeriodToDate)}},model:{value:(_vm.secondPeriodToDate),callback:function ($$v) {_vm.secondPeriodToDate=$$v},expression:"secondPeriodToDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("End date")])]],2)],1)],1)]),_c('v-menu',{staticClass:"filter-menu",attrs:{"close-on-content-click":false,"max-width":"312px","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-btn ml-auto",attrs:{"color":"primary","text":"","medium":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Filter settings")])]),_c('v-list',[(_vm.hasDraftConditionRanges)?_c('v-list-item',[_c('v-select',{staticClass:"conditions-select input elevation-0 d-inline-flex mb-4",attrs:{"items":_vm.conditionsList,"item-text":"text","item-value":"text","label":"Condition","persistent-placeholder":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.condition),callback:function ($$v) {_vm.condition=$$v},expression:"condition"}})],1):_vm._e(),_c('v-list-item',[_c('v-select',{staticClass:"interval-select input elevation-0 d-inline-flex mb-4",attrs:{"items":_vm.intervalList,"item-text":"text","item-value":"value","label":"Data interval","persistent-placeholder":"","hide-details":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g({attrs:{"two-line":"","disabled":!_vm.isDataIntervalAllowedForDataSize(item.value)}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),(!_vm.isDataIntervalAllowedForDataSize(item.value))?_c('v-list-item-subtitle',[_vm._v(" Not possible to fetch due to the date range ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.granularity),callback:function ($$v) {_vm.granularity=$$v},expression:"granularity"}})],1),_c('v-list-item',{staticClass:"flex-column align-start"},[_c('span',{staticClass:"speed-range-label d-flex"},[_vm._v("Speed Range "),_c('b',{staticClass:"value mx-1"},[_vm._v("["+_vm._s(_vm.speed[0])+" knots – "+_vm._s(_vm.speed[1])+" knots]")])]),_c('v-range-slider',{staticClass:"speed-range",staticStyle:{"width":"100%"},attrs:{"step":1,"min":0,"max":25,"hide-details":"auto","strict":""},model:{value:(_vm.speed),callback:function ($$v) {_vm.speed=$$v},expression:"speed"}})],1),_c('v-list-item',[_c('div',{staticClass:"info-text"},[_vm._v("Configured Beaufort scale and MCR filters are also applied to the propulsion efficiency data.")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"accent"},on:{"click":function($event){return _vm.applyFilter()}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"font-weight-semibold",on:{"click":function($event){return _vm.closeFilterMenu()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1),(_vm.averageKPI)?_c('p',{staticClass:"average-text"},[_vm._v(" AVG change in Shaft Power (Period #1 vs Period #2): "),_c('b',[_vm._v(_vm._s(_vm.averageKPI))]),_vm._v("% "),_c('v-tooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1317258515)},[_c('span',[_vm._v(_vm._s(_vm.avgChangeShaftPowerTooltipText))])])],1):_c('p',{staticClass:"average-text font-weight-medium"},[_vm._v(" N/A No Overlapping Points. "),_c('v-tooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.avgChangeShaftPowerTooltipText))])])],1)]),_c('v-card-text',[_c('div',{staticClass:"chart__loader-overlay",class:{ 'active': _vm.isDataLoading }},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1),_c('Highcharts',{ref:"chart",staticClass:"propulsion-efficency-chart",attrs:{"options":_vm.ChartOptions,"deepCopyOnUpdate":false,"callback":_vm.chartReady}})],1),_c('v-dialog',{attrs:{"max-width":"1350px","scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.expandChart = false}},model:{value:(_vm.expandChart),callback:function ($$v) {_vm.expandChart=$$v},expression:"expandChart"}},[_c('v-card',[_c('v-toolbar',{staticClass:"font-weight-semibold",attrs:{"color":"accentDarker110","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Propulsion Efficency")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.expandChart = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-title',[_c('v-menu',{staticClass:"filter-menu",attrs:{"close-on-content-click":false,"max-width":"312px","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-btn ml-auto",attrs:{"color":"primary","text":"","medium":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.filterMenuModal),callback:function ($$v) {_vm.filterMenuModal=$$v},expression:"filterMenuModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Filter settings")])]),_c('v-list',[(_vm.hasDraftConditionRanges)?_c('v-list-item',[_c('v-select',{staticClass:"conditions-select input elevation-0 d-inline-flex mb-4",attrs:{"items":_vm.conditionsList,"item-text":"text","item-value":"text","label":"Condition","persistent-placeholder":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.condition),callback:function ($$v) {_vm.condition=$$v},expression:"condition"}})],1):_vm._e(),_c('v-list-item',[_c('v-select',{staticClass:"interval-select input elevation-0 d-inline-flex mb-4",attrs:{"items":_vm.intervalList,"item-text":"text","item-value":"value","label":"Data interval","persistent-placeholder":"","hide-details":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g({attrs:{"two-line":"","disabled":!_vm.isDataIntervalAllowedForDataSize(item.value)}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.text))]),(!_vm.isDataIntervalAllowedForDataSize(item.value))?_c('v-list-item-subtitle',[_vm._v(" Not possible to fetch due to the date range ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.granularity),callback:function ($$v) {_vm.granularity=$$v},expression:"granularity"}})],1),_c('v-list-item',{staticClass:"flex-column align-start"},[_c('span',{staticClass:"speed-range-label d-flex"},[_vm._v("Speed Range "),_c('b',{staticClass:"value mx-1"},[_vm._v("["+_vm._s(_vm.speed[0])+" knots – "+_vm._s(_vm.speed[1])+" knots]")])]),_c('v-range-slider',{staticClass:"speed-range",staticStyle:{"width":"100%"},attrs:{"step":1,"min":0,"max":25,"hide-details":"auto","strict":""},model:{value:(_vm.speed),callback:function ($$v) {_vm.speed=$$v},expression:"speed"}})],1),_c('v-list-item',[_c('div',{staticClass:"info-text"},[_vm._v("Configured Beaufort scale and MCR filters are also applied to the propulsion efficiency data.")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"primary"},on:{"click":function($event){return _vm.applyFilter()}}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"font-weight-semibold",on:{"click":function($event){return _vm.closeFilterMenu()}}},[_vm._v(" Cancel ")])],1)],1)],1)],1),_c('v-card-text',{staticClass:"pt-6"},[_c('Highcharts',{ref:"chart",staticClass:"propulsion-efficency-chart",staticStyle:{"height":"700px"},attrs:{"options":_vm.ChartOptions,"deepCopyOnUpdate":false,"callback":_vm.chartReady}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }