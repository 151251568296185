






































































import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Fouling = getModule(FoulingModule, store);

@Component
export default class BenchmarkSettingsModal extends Vue {
  @PropSync("isOpen", { type: Boolean, default: false }) dialog!: boolean;

  benchmark: { vesselId: number; benchmark: number } = { vesselId: this.vessel?.id ?? 0, benchmark: 0 };
  foulingChartConfig: FoulingChartConfig = {} as FoulingChartConfig;
  useManualBenchmarkLevel = false;

  // @Watchers
  @Watch("foulingChartConfig")
  onFoulingChartConfigChanged(): void {
    this.foulingChartConfig = this.getFoulingChartConfig;
  }

  // @Getters
  get disableSaveBenchmarkLevelSettings(): boolean {
    return this.foulingChartConfig.benchmark > 40 || this.foulingChartConfig.benchmark < -40;
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  // @Methods
  benchmarkLevelInputRules(value: any): boolean | string {
    if (!!!value && this.foulingChartConfig.useManualBenchmark) return "Required";
    else return (-40 <= value && value <= 40) || "Benchmark level must be a number between -40 and 40.";
  }

  open(): void {
    this.dialog = true;
  }

  resetManualBenchmarkLevel(): void {
    if (!!!this.foulingChartConfig.benchmark) {
      this.foulingChartConfig.benchmark = 0;
    }
  }

  cancel(): void {
    this.dialog = false;
  }

  async updateBenchmarkLevelSettings(): Promise<void> {
    try {
      await Fouling.updateFoulingChartConfig(this.foulingChartConfig);
      await Fouling.fetchFoulingChartConfig(this.vessel!.id);
      Snackbar.showSnackbar({ text: "Benchmark settings successfully updated", color: "success" });
      this.$emit("benchmarkUpdated");
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update benchmark settings" });
    } finally {
      this.dialog = false;
    }
  }

  async created(): Promise<void> {
    if (!this.vessel) return;
    this.foulingChartConfig = await Fouling.fetchFoulingChartConfig(this.vessel.id);
  }
}
