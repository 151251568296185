

























































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  highcharts
import { Chart } from "highcharts-vue";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import Highcharts from "highcharts";
HighchartsNoData(Highcharts);
//  types
import { ExtendedVessel } from "@/types/Vessel";
import { IDataSerie } from "@/types/highcharts/dataSerie";
import { IPlotLine, DefaultVesselEventPlotline } from "@/types/highcharts/plotLine";
import { IPlotBand } from "@/types/highcharts/plotBand";
import { VesselEvent } from "@/types/vesselEvent";
import { SpeedLossHistory } from "@/types/SpeedLossHistory";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { HullCoating } from "@/types/HullCoating";
import { TrendPeriodMeta } from "@/types/TrendPeriodMeta";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselEventsModule from "@/store/clients/VesselEvents.module";
import FoulingModule from "@/store/clients/Fouling.module";

const Vessels = getModule(VesselsModule, store);
const VesselEvents = getModule(VesselEventsModule, store);
const Fouling = getModule(FoulingModule, store);

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class SpeedLossChartCard extends Vue {
  @Prop() readonly speedLossHistory!: SpeedLossHistory[];
  @Prop() readonly speedLossStatistics!: SpeedLossStatistic[];
  @Prop() readonly isBenchmarking!: boolean;
  @Prop() readonly isDataLoading!: boolean;
  @Prop() readonly isDerivedStwEnabled!: boolean;

  chart!: any;
  chartLoaded = false;

  // @Getters
  get hullCoatingVessel(): HullCoating | null {
    return Fouling.hullCoatingVessel ?? null;
  }

  get getFoulingChartConfig(): FoulingChartConfig {
    return Fouling.foulingChartConfig;
  }

  get speedMeasurementSource(): string {
    return this.getFoulingChartConfig.useDerivedStw ? "Derived STW" : "Speed Log";
  }

  get latestSpeedLossStatistics(): SpeedLossStatistic | null {
    return this.speedLossStatistics[this.speedLossStatistics.length - 1] ?? null;
  }

  get greenZoneEnds(): number {
    if (!this.latestSpeedLossStatistics) return 0 - 5;
    return this.latestSpeedLossStatistics.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  get state(): string {
    if (this.isBenchmarking) return "benchmarking";
    if (this.latestSpeedLossStatistics == null) return "N/A";
    if (this.latestSpeedLossStatistics.trendEndValue < this.yellowZoneEnds) return "high";
    if (this.latestSpeedLossStatistics.trendEndValue < this.greenZoneEnds && this.latestSpeedLossStatistics.trendEndValue > this.yellowZoneEnds) return "medium";
    if (this.latestSpeedLossStatistics.trendEndValue > this.greenZoneEnds) return "normal";
    return "N/A";
  }

  get stateicon(): string {
    switch (this.state) {
      case "high":
        return "mdi-alert-circle";
      case "medium":
        return "mdi-alert-circle";
      case "normal":
        return "mdi-check-circle";
      case "benchmarking":
        return "mdi-gauge";
      default:
        return "mdi-check-circle";
    }
  }

  get statecolor(): string {
    switch (this.state) {
      case "high":
        return "#ff5252";
      case "medium":
        return "#ffc107";
      case "normal":
        return "#4caf50";
      case "benchmarking":
        return "#003B42";
      default:
        return "#fff";
    }
  }

  get ChartOptions(): any {
    if (!this.chartLoaded) return {};
    const ctx: any = this;

    const options = {
      time: { useUTC: false },
      chart: {
        type: "line",
        zoomType: "x",
        spacingRight: 20,
        animation: false,
        height: 400,
        spacingTop: 40,
        style: { fontFamily: "Helvetica Neue" },
        events: {
          selection: function (e: any) {
            const $this: any = this;
            setTimeout(() => {
              if (!e.resetSelection) ctx.$emit("onShipSpeedLogChartSelection", { min: $this.xAxis[0].min, max: $this.xAxis[0].max });
            });
          },
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      title: {
        text: null,
      },
      yAxis: {
        title: {
          // text: `${this.firstLongTrendPeriod.unitName} (${this.firstLongTrendPeriod.unitCaption})`,
          text: "Percent (%)",
        },
        // min: this.firstLongTrendPeriod.isRpmDiagnostic ? -25 : -50,
        // max: this.firstLongTrendPeriod.isRpmDiagnostic ? 25 : 50,
        // min: Math.min(...this.speedLossHistory.map(item => item.speedLossPercent)),
        // max: Math.max(...this.speedLossHistory.map(item => item.speedLossPercent)),
        min: -50,
        max: 50,
        tickPixelInterval: 20,
      },
      xAxis: {
        title: {
          text: null,
        },
        type: "datetime",
        minRange: 1,
        plotLines: this.vesselEventPlotLines,
        plotBands: this.vesselEventPlotBands,
        labels: {
          y: 35,
        },
      },
      series: this.dataSeries,
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, .85)",
        borderWidth: 2,
        style: {
          color: "#EBEBEB",
        },
      },
      plotOptions: {
        series: {
          zIndex: 1,
        },
      },
      exporting: {
        // filename: `${this.vessel?.name}_${this.longTrend.descriptionLong}`,
        filename: `${this.vessel?.name}_`,
        chartOptions: {
          legend: {
            enabled: false,
          },
          title: {
            // text: `${this.vessel?.name} [${this.longTrend.descriptionLong}]`,
            text: `${this.vessel?.name}`,
            style: {
              width: "450px",
            },
          },
        },
      },
    };

    return options;
  }

  get speedLossPoints(): IDataSerie {
    return {
      name: "Deviation from baseline",
      type: "line",
      color: "#800000",
      zIndex: 1,
      enableMouseTracking: true,
      cropThreshold: 9999,
      marker: {
        symbol: "circle",
      },
      tooltip: {
        useHTML: true,
        headerFormat: "<small>{point.key}</small><br>",
        pointFormat: "Deviation from baseline: <strong>{point.y}</strong>",
        valueDecimals: 1,
        valueSuffix: "%",
      },
      data: this.speedLossHistory.map(item => [Date.parse(`${item.timestamp}`), item.speedLossPercent]),
    };
  }

  get trendLines(): IDataSerie[] {
    const trendLines: IDataSerie[] = [];
    this.filteredStatistics.forEach(item => {
      trendLines.push({
        name: `Trendline (${moment.utc(item.fromDate).format("DD.MMM YYYY")})`,
        type: "line",
        color: "#008000",
        zIndex: 2,
        cropThreshold: 9999,
        marker: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 1,
          valueSuffix: "%",
        },
        data: [
          [Date.parse(`${item.fromDate}`), item.trendLine.b],
          [Date.parse(`${item.toDate}`), item.trendEndValue],
        ],
      });
    });
    return trendLines;
  }

  get firstSpeedLossDate(): string {
    return this.speedLossHistory[0].timestamp;
  }

  get lastSpeedLossDate(): string {
    return this.speedLossHistory[this.speedLossHistory.length - 1].timestamp;
  }

  get filteredStatistics(): SpeedLossStatistic[] {
    const filteredStatistics = this.speedLossStatistics.filter(s => s.fromDate >= this.firstSpeedLossDate);
    if (filteredStatistics.length <= 0) {
      const lastStatistics = this.speedLossStatistics[this.speedLossStatistics.length - 1];
      if (lastStatistics) filteredStatistics.push(lastStatistics);
    }
    return filteredStatistics;
  }

  get benchmarkLine(): IDataSerie {
    const benchmarkingData: [number, number][] = [];
    this.filteredStatistics.forEach(item => benchmarkingData.push([Date.parse(`${item.fromDate}`), item.benchmark.level], [Date.parse(`${item.toDate}`), item.benchmark.level]));

    return {
      name: "Benchmark",
      type: "line",
      dashStyle: "dash",
      color: "#0000FF",
      cropThreshold: 9999,
      zIndex: 4,
      visible: false,
      tooltip: {
        valueDecimals: 1,
        valueSuffix: "%",
      },
      data: benchmarkingData,
    };
  }

  get baseline(): IDataSerie {
    if (!this.speedLossStatistics.length) return {} as IDataSerie;
    const fromDate = this.firstSpeedLossDate;
    const toDate = this.lastSpeedLossDate;
    return {
      name: "Baseline",
      type: "line",
      color: "#000000",
      cropThreshold: 9999,
      lineWidth: 1,
      enableMouseTracking: false,
      marker: {
        enabled: false,
      },
      data: [
        [Date.parse(`${fromDate}`), 0],
        [Date.parse(`${toDate}`), 0],
      ],
    };
  }

  get dataSeries(): IDataSerie[] {
    if (!this.speedLossHistory.length) return [] as IDataSerie[];
    let series: IDataSerie[] = [];

    series = [this.speedLossPoints, ...this.trendLines, this.benchmarkLine, this.baseline];

    return series;
  }

  get vesselEventPlotLines(): IPlotLine[] {
    const plotLines: IPlotLine[] = [];

    this.vesselEvents.forEach(event => {
      const html = this.getPlotLineLabels(this.getVesselEventsOnSameDate(event.timestamp), event);
      plotLines.push(
        Object.assign(JSON.parse(JSON.stringify(DefaultVesselEventPlotline)), {
          id: `plotline-${event.id}`,
          color: this.eventColor(event.type),
          value: moment.utc(event.timestamp).valueOf(),
          width: 2,
          label: {
            rotation: 0,
            style: { color: this.eventColor(event.type) },
            text: html,
            useHTML: true,
            y: -15,
            x: -11,
          },
        })
      );
    });

    return plotLines;
  }

  get vesselEventPlotBands(): IPlotBand[] {
    const plotBands: IPlotBand[] = [];

    this.speedLossStatistics.forEach((period, index) => {
      plotBands.push({
        from: moment.utc(period.fromDate).valueOf(),
        to: moment.utc(period.toDate).valueOf(),
        id: index,
        className: "speed-loss-graph__plot-bands",
        color: "rgba(0,0,0,0.0)",
        zIndex: 4,
        events: {
          click: (e: any) => {
            setTimeout(() => {
              if (e.xAxis || e.yAxis) this.$emit("plotBandClicked", this.getTrendPeriodMeta(period));
            });
          },
          mouseover: (e: any) => {
            e.target.attributes.getNamedItem("fill").value = "#ccd9cc4D";
          },
          mouseout: (e: any) => {
            e.target.attributes.getNamedItem("fill").value = "rgba(0,0,0,0.0)";
          },
        },
      });
    });

    return plotBands;
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get vesselEvents(): VesselEvent[] {
    return VesselEvents.allEvents;
  }

  get chartTitleTooltipText(): string {
    return (
      "This graph shows a long trend history of speed loss compared to the baseline used for the vessel. The baseline for the vessel is typically the power vs. speed design curve from the model tank test. A zero (0%) speed loss means that the vessel is performing according to the design curve." +
      "A normal trend line for a vessel shows decreasing speed loss over time (negative values), primarily due to hull fouling and reduced propeller efficiency. The system calculates a speed loss percentage for each day by taking an average of all the speed loss measurements calculated from the high frequency data (usually 1 or 15 second granularity) within the 24 hour period." +
      "These higher frequency data points are averaged up to a single speed loss percentage for the day which is used to compute a trend line (green line) within the trend period. The system automatically adjusts the calculation for laden and ballast states. Note: the speed loss metric in the Fouling tab may have a different result than in the Diagnostics tab due to different filters applied to the data."
    );
  }

  //  @Methods
  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  getVesselEventsOnSameDate(eventTimestamp: string): VesselEvent[] {
    return this.vesselEvents.filter((vesselEvent: VesselEvent) => vesselEvent.timestamp === eventTimestamp);
  }

  getPlotLineLabels(vesselEventsOnSameDate: VesselEvent[], event: VesselEvent): string {
    return `
            <span class="plot-line-label-icon mdi ${this.eventIcon(event.type)}">
              <i
                class="multiple-event-counter"
                style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
              >
                ${vesselEventsOnSameDate.length}
              </i>
            </span>
            <div class="plot-line-tooltip">
              ${vesselEventsOnSameDate
                .map(
                  (vesselEvent: VesselEvent, index: number) => `
                <p>
                  <i
                    class="plot-line-label-icon mdi ${this.eventIcon(vesselEvent.type)} mr-2"
                    style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
                  ></i>
                  <b>${moment.utc(vesselEvent.timestamp).format("DD.MMM YYYY")}</b>
                </p>
                <p>${vesselEvent.name}</p>
                <hr class="my-2" style="display: ${vesselEventsOnSameDate.length - 1 === index ? "none" : "block"}" />
              `
                )
                .join("")}
            </div>
            `;
  }

  getTrendPeriod(trendPeriod: SpeedLossStatistic, type: string): SpeedLossStatistic | null {
    if (!type) return trendPeriod;
    let currentTrendPeriod = null;
    this.speedLossStatistics.forEach((item, i) => {
      if (item.fromDate === trendPeriod.fromDate) {
        currentTrendPeriod = this.speedLossStatistics[type === "next" ? i + 1 : i - 1];
      }
    });
    return currentTrendPeriod;
  }

  getTrendPeriodMeta(trendPeriod: SpeedLossStatistic): TrendPeriodMeta {
    return {
      vesselId: this.vessel?.id ?? null,
      fromDate: trendPeriod.fromDate,
      toDate: trendPeriod.toDate,
      nextPeriodStartDate: this.getTrendPeriod(trendPeriod, "next")?.fromDate ?? null,
      previousPeriodStartDate: this.getTrendPeriod(trendPeriod, "previous")?.fromDate ?? null,
      benchmark: trendPeriod.benchmark,
      trendEndValue: trendPeriod.trendEndValue,
      trendLine: trendPeriod.trendLine,
      speedLossHistory: this.speedLossHistory.filter(
        item => moment.utc(item.timestamp).valueOf() >= moment.utc(trendPeriod.fromDate).valueOf() && moment.utc(item.timestamp).valueOf() <= moment.utc(trendPeriod.toDate).valueOf()
      ),
    };
  }

  zoomIn(timestampFrom: string, timestampTo: string, zoomedOut: boolean): void {
    this.chart.xAxis[0].zoom(timestampFrom, timestampTo);
    this.chart.redraw();
    this.chart.showResetZoom();
  }

  eventIcon(eventType: string): string {
    return eventType === "TrendEvent" ? "mdi-alpha-t-circle" : "mdi-alpha-i-circle";
  }

  eventColor(eventType: string): string {
    return eventType === "InfoEvent" ? "#0060fe" : "#008000";
  }

  formatDatetimeToUTC(datetime: any): any {
    datetime = datetime.split("+");
    return datetime[0].endsWith("z") || datetime[0].endsWith("Z") ? datetime[0] : datetime[0] + "Z";
  }

  onEditSpeedSource(): void {
    this.$emit("onEditSpeedSource");
  }

  onEditHullSource(): void {
    this.$emit("onEditHullSource");
  }
}
