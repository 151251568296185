import { render, staticRenderFns } from "./SpeedLossChartCard.vue?vue&type=template&id=c071e1c4&scoped=true&"
import script from "./SpeedLossChartCard.vue?vue&type=script&lang=ts&"
export * from "./SpeedLossChartCard.vue?vue&type=script&lang=ts&"
import style0 from "./SpeedLossChartCard.vue?vue&type=style&index=0&id=c071e1c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c071e1c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VIcon,VProgressCircular,VTooltip})
