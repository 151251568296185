var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"font-weight-semibold justify-space-between align-start main-title flex-nowrap",staticStyle:{"min-height":"116px"},attrs:{"color":"primary"}},[_c('div',{staticClass:"title d-flex flex-column"},[_c('div',{staticClass:"text mb-3"},[_c('span',{staticClass:"font-weight-semibold",attrs:{"color":"primary"}},[_vm._v("Operational Profile")]),_c('v-tooltip',{attrs:{"right":"","max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":"","medium":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("This chart shows the locations the vessel has sailed for the selected time period and the seawater temperature associated with each location. The longer a vessel idles in warm waters (25 degrees Celsius and above), the higher risk of fouling, which increases speed loss. You can use this feature to understand how the vessel's operational profile contributed to its speed loss over time. Click on the three small dots on the right side of the chart in order to expand the map and see it in full screen.")])])],1)]),_c('div',{staticClass:"actions d-inline-flex flex-column ml-auto pt-3"},[_c('div',{staticClass:"date-time input d-inline-flex"},[_c('label',[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-range")]),_vm._v("Date range")],1),_c('v-menu',{ref:"menuFromDate",attrs:{"close-on-content-click":false,"return-value":_vm.fromDate,"transition":"slide-x-transition","min-width":"auto","nudge-left":220,"offset-y":""},on:{"update:returnValue":function($event){_vm.fromDate=$event},"update:return-value":function($event){_vm.fromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fromDate)+" ")])]}}]),model:{value:(_vm.isFromDateModalActive),callback:function ($$v) {_vm.isFromDateModalActive=$$v},expression:"isFromDateModalActive"}},[_c('v-date-picker',{attrs:{"max":_vm.currentDate},on:{"input":function($event){return _vm.onDateRangeChanged('menuFromDate', _vm.fromDate)}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("Start date")])]],2)],1),_c('span',{staticClass:"mx-1 d-flex align-center",staticStyle:{"color":"#666","opacity":"0.8"}},[_vm._v("–")]),_c('v-menu',{ref:"menuToDate",attrs:{"close-on-content-click":false,"return-value":_vm.toDate,"transition":"slide-x-transition","nudge-left":220,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.toDate=$event},"update:return-value":function($event){_vm.toDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"date-selection d-flex align-center"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.toDate)+" ")])]}}]),model:{value:(_vm.isToDateModalActive),callback:function ($$v) {_vm.isToDateModalActive=$$v},expression:"isToDateModalActive"}},[_c('v-date-picker',{attrs:{"color":"primary lighten-2","min":_vm.fromDate,"max":_vm.currentDate},on:{"input":function($event){return _vm.onDateRangeChanged('menuToDate', _vm.toDate)}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[[_c('div',{staticClass:"datepicker-fake-title"},[_vm._v("End date")])]],2)],1)],1),(_vm.invalidRange)?_c('div',{staticClass:"error-text-container"},[_c('p',{staticClass:"date-range-error-text"},[_vm._v(" Please select a period shorter than 5 years. ")])]):_vm._e()]),(!_vm.isModal)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,528074481)},[_c('v-list',{staticClass:"ship-speed-loss-settings-list"},[_c('v-list-item',{on:{"click":function($event){return _vm.expandMap()}}},[_c('v-list-item-title',[_vm._v("Expand map")])],1)],1)],1):_vm._e()],1),_c('v-card-text',[_c('div',{staticClass:"total-idling-hours font-weight-medium"},[_vm._v(" Total time spent idling in warm water: "),_c('b',[_vm._v(_vm._s(_vm.hoursInWarmWater))]),_c('v-tooltip',{attrs:{"max-width":"350px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" The KPI is computed by summing the total hours spent when the vessel was sailing below 5kts STW AND the seawater temperature was above 25 degrees Celsius."),_c('br'),_vm._v(" The KPI automatically adjusts based on the time period selected. ")])])],1),_c('Map',{ref:"Map",staticClass:"mt-5",style:(_vm.mapHeight),attrs:{"vessels":[_vm.vessel],"enableMinimalMode":true,"weather-sea-surface-temperature":true,"sea-temperature-date-range":_vm.getSeaTemperatureDateRange,"is-synchronized":_vm.syncSpeedLog,"isUsingDerivedStw":_vm.isUsingDerivedStw},on:{"onMarkerClicked":_vm.onMarkerClicked,"onTrackClicked":_vm.onTrackClicked,"onOperationalProfileZoom":_vm.onMapZoom}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }